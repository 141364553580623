import { navigate } from "gatsby";
import React, { useState } from "react";
import { Box } from "../components/box";
import { GoatExaminationStep } from "../components/steps/goat-examination-step";
import { Layout } from "../components/layout";
import { MaxWidthContainer } from "../components/max-width-container";
import { PageContainer } from "../components/page-container";
import {
  initialParentFormValuesByStep,
  parentFormStepOrder,
  parentFormSteps,
} from "../constants";
import { StandardStep } from "../components/steps/standard-step";
import { GuidanceStep } from "../components/steps/guidance-step";
import { InitialStep } from "../components/steps/initial-step";
import { getAdviceItems } from "../guidance";

const Form = () => {
  const [values, setValues] = useState(initialParentFormValuesByStep);
  const [currentStep, setCurrentStep] = useState(parentFormStepOrder[0]);

  const currentStepIndex = parentFormStepOrder.findIndex(
    (step) => step === currentStep
  );
  const setValuesByStep = (step, values) =>
    setValues((prevState) => ({ ...prevState, [step]: values }));

  const addGoat = (newGoat) =>
    setValuesByStep(currentStep, {
      goats: [...values[currentStep].goats, newGoat],
    });

  const onBackClick = (values) => {
    setValuesByStep(currentStep, values);
    setCurrentStep(parentFormStepOrder[currentStepIndex - 1]);
  };

  const onNextClick = (values) => {
    window.scrollTo(0, 0);
    setValuesByStep(currentStep, values);
    setCurrentStep(parentFormStepOrder[currentStepIndex + 1]);
  };

  const onRestartClick = () => {
    setCurrentStep(parentFormStepOrder[0]);
    setValues(initialParentFormValuesByStep);
    window.scrollTo(0, 0);
  };

  return (
    <Layout>
      <PageContainer>
        <MaxWidthContainer>
          <Box>
            {currentStep === parentFormSteps.FARMER_DETAILS && (
              <InitialStep
                step={currentStep}
                initialValues={values[currentStep]}
                onCancelClick={() => navigate("/")}
                onNextClick={onNextClick}
              />
            )}
            {currentStep === parentFormSteps.FARMING_SYSTEMS && (
              <StandardStep
                step={currentStep}
                initialValues={values[currentStep]}
                onBackClick={onBackClick}
                onNextClick={onNextClick}
              />
            )}
            {currentStep === parentFormSteps.GOAT_HEALTH_CONCERNS && (
              <StandardStep
                step={currentStep}
                initialValues={values[currentStep]}
                onBackClick={onBackClick}
                onNextClick={onNextClick}
              />
            )}
            {currentStep === parentFormSteps.GOAT_FORM && (
              <GoatExaminationStep
                step={currentStep}
                goats={values[currentStep].goats}
                addGoat={addGoat}
                onBackClick={() =>
                  setCurrentStep(parentFormStepOrder[currentStepIndex - 1])
                }
                onNextClick={() =>
                  setCurrentStep(parentFormStepOrder[currentStepIndex + 1])
                }
              />
            )}
            {currentStep === parentFormSteps.GUIDANCE && (
              <GuidanceStep
                items={getAdviceItems(values)}
                onBackClick={() =>
                  setCurrentStep(parentFormStepOrder[currentStepIndex - 1])
                }
                onDoneClick={() => navigate("/")}
                onRestartClick={onRestartClick}
              />
            )}
          </Box>
        </MaxWidthContainer>
      </PageContainer>
    </Layout>
  );
};

export default Form;
