import css from "@styled-system/css";
import styled from "styled-components";

export const ListItem = styled.div`
  ${css({
    backgroundColor: "background.tertiary",
    borderRadius: [2],
    marginBottom: [2],
    padding: [1],
    "&:last-child": {
      marginBottom: [0],
    },
  })}
`;
