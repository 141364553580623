import React, { useRef, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import * as Styles from "./goat-examination-step.styles";
import "./custom-styling.css";
import {
  goatExaminationFormStepOrder,
  goatExaminationFormSteps,
  initialGoatFormValuesByStep,
} from "../../../constants";
import { BoxContent } from "../../box";
import { Button } from "../../button";
import { Spacer } from "../../spacer";
import { GoatsList } from "../../goats-list";
import { StandardStep } from "../standard-step";
import { FinalStep } from "../final-step";
import { TwoButtons } from "../buttons/two-buttons";
import { H2, Subtitle, Text } from "../../text";
import { InitialStep } from "../initial-step";

export const GoatExaminationStep = ({
  step,
  goats,
  addGoat,
  onBackClick,
  onNextClick,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState(initialGoatFormValuesByStep);
  const [currentStep, setCurrentStep] = useState(
    goatExaminationFormStepOrder[0]
  );

  const subtitleRef = useRef();

  const currentStepIndex = goatExaminationFormStepOrder.findIndex(
    (step) => step === currentStep
  );

  const setValuesByStep = (step, values) =>
    setValues((prevState) => ({ ...prevState, [step]: values }));

  const onOpenModal = () => {
    setIsModalOpen(true);
    setValues(initialGoatFormValuesByStep);
  };

  const onFormReset = () => {
    setCurrentStep(goatExaminationFormStepOrder[0]);
    setValues(initialGoatFormValuesByStep);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    onFormReset();
  };

  const onDoneClick = (stepValues) => {
    const updatedGoat = { ...values, [currentStep]: stepValues };
    setValuesByStep(currentStep, stepValues);
    addGoat(updatedGoat);
    onCloseModal();
  };

  const onRestartClick = (stepValues) => {
    setValuesByStep(currentStep, stepValues);
    addGoat(values);
    onFormReset();
    subtitleRef.current && subtitleRef.current.scrollIntoView();
  };

  const onModalBackClick = (stepValues) => {
    setValuesByStep(currentStep, stepValues);
    setCurrentStep(goatExaminationFormStepOrder[currentStepIndex - 1]);
  };

  const onModalNextClick = (stepValues) => {
    setValuesByStep(currentStep, stepValues);
    setCurrentStep(goatExaminationFormStepOrder[currentStepIndex + 1]);
    subtitleRef.current && subtitleRef.current.scrollIntoView();
  };

  return (
    <>
      <BoxContent>
        <Styles.HeadingWrapper>
          <H2>{step}</H2>
          <Spacer width={2} />
          <Button onClick={onOpenModal} small>
            Add goat
          </Button>
        </Styles.HeadingWrapper>
        <Text>Please add a form for each goat examined.</Text>
        <Spacer height={2} />
        <GoatsList goats={goats} onAddGoatClick={onOpenModal} />
        <Spacer height={1} />
        <Modal open={isModalOpen} onClose={onCloseModal}>
          <Subtitle ref={subtitleRef}>Goat {goats.length + 1}</Subtitle>
          {currentStep === goatExaminationFormSteps.GENERAL_PARAMETERS && (
            <InitialStep
              step={currentStep}
              initialValues={values[currentStep]}
              onCancelClick={onCloseModal}
              onNextClick={onModalNextClick}
            />
          )}
          {currentStep === goatExaminationFormSteps.DISTANCE_EXAMINATION && (
            <StandardStep
              step={currentStep}
              initialValues={values[currentStep]}
              onBackClick={onModalBackClick}
              onNextClick={onModalNextClick}
            />
          )}
          {currentStep === goatExaminationFormSteps.PHYSICAL_EXAMINATION && (
            <FinalStep
              step={currentStep}
              initialValues={values[currentStep]}
              onBackClick={onModalBackClick}
              onDoneClick={onDoneClick}
              onRestartClick={onRestartClick}
            />
          )}
        </Modal>
      </BoxContent>
      <Spacer height={2} />
      <TwoButtons
        onLeftButtonClick={() => onBackClick(values)}
        leftButtonText="Back"
        onRightButtonClick={() => onNextClick(values)}
        rightButtonText="Next"
      />
    </>
  );
};
