import React, { memo, useState } from "react";
import * as Styles from "./accordion.styles";
import { ArrowIcon } from "../icons/arrow";
import { H3 } from "../text";
import { Spacer } from "../spacer";

export const Accordion = memo(({ title, content }) => {
  const [accordionOpen, setAccordionOpen] = useState(true);

  return (
    <Styles.Wrapper>
      <Styles.Header
        isOpen={accordionOpen}
        onClick={() => setAccordionOpen(!accordionOpen)}
      >
        <H3>{title}</H3>
        <Spacer width={2} />
        <ArrowIcon
          orientation={accordionOpen ? "top" : ""}
          variant="background.secondary"
        />
      </Styles.Header>
      <Styles.Content isOpen={accordionOpen}>{content}</Styles.Content>
    </Styles.Wrapper>
  );
});
