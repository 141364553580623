import FarmerDetailsQuestions from "./questions/parent_form/farmer_details.json";
import FarmingSystemsQuestions from "./questions/parent_form/farming_systems.json";
import GoatHealthConcernsQuestions from "./questions/parent_form/goat_health_concerns.json";
import GeneralParametersQuestions from "./questions/goat_form/general_parameters.json";
import DistanceExaminationQuestions from "./questions/goat_form/distance_examination.json";
import PhysicalExaminationQuestions from "./questions/goat_form/physical_examination.json";
import FarmerDetailsInitialValues from "./initial-values/parent_form/farmer_details.json";
import FarmingSystemsInitialValues from "./initial-values/parent_form/farming_systems.json";
import GoatHealthConcernsInitialValues from "./initial-values/parent_form/goat_health_concerns.json";
import GeneralParametersInitialValues from "./initial-values/goat_form/general_parameters.json";
import DistanceExaminationInitialValues from "./initial-values/goat_form/distance_examination.json";
import PhysicalExaminationInitialValues from "./initial-values/goat_form/physical_examination.json";

export const parentFormSteps = {
  FARMER_DETAILS: "Farmer Details",
  FARMING_SYSTEMS: "Farming Systems",
  GOAT_HEALTH_CONCERNS: "Goat Health Concerns",
  GOAT_FORM: "Individual Goat Details",
  GUIDANCE: "Guidance",
};

export const parentFormStepOrder = [
  parentFormSteps.FARMER_DETAILS,
  parentFormSteps.FARMING_SYSTEMS,
  parentFormSteps.GOAT_HEALTH_CONCERNS,
  parentFormSteps.GOAT_FORM,
  parentFormSteps.GUIDANCE,
];

export const goatExaminationFormSteps = {
  GENERAL_PARAMETERS: "General Parameters",
  DISTANCE_EXAMINATION: "Distance Examination",
  PHYSICAL_EXAMINATION: "Physical Examination",
};

export const goatExaminationFormStepOrder = [
  goatExaminationFormSteps.GENERAL_PARAMETERS,
  goatExaminationFormSteps.DISTANCE_EXAMINATION,
  goatExaminationFormSteps.PHYSICAL_EXAMINATION,
];

export const questionsByStep = {
  [parentFormSteps.FARMER_DETAILS]: FarmerDetailsQuestions,
  [parentFormSteps.FARMING_SYSTEMS]: FarmingSystemsQuestions,
  [parentFormSteps.GOAT_HEALTH_CONCERNS]: GoatHealthConcernsQuestions,
  [goatExaminationFormSteps.GENERAL_PARAMETERS]: GeneralParametersQuestions,
  [goatExaminationFormSteps.DISTANCE_EXAMINATION]: DistanceExaminationQuestions,
  [goatExaminationFormSteps.PHYSICAL_EXAMINATION]: PhysicalExaminationQuestions,
};

export const initialParentFormValuesByStep = {
  [parentFormSteps.FARMER_DETAILS]: FarmerDetailsInitialValues,
  [parentFormSteps.FARMING_SYSTEMS]: FarmingSystemsInitialValues,
  [parentFormSteps.GOAT_HEALTH_CONCERNS]: GoatHealthConcernsInitialValues,
  [parentFormSteps.GOAT_FORM]: { goats: [] },
};

export const initialGoatFormValuesByStep = {
  [goatExaminationFormSteps.GENERAL_PARAMETERS]: GeneralParametersInitialValues,
  [goatExaminationFormSteps.DISTANCE_EXAMINATION]:
    DistanceExaminationInitialValues,
  [goatExaminationFormSteps.PHYSICAL_EXAMINATION]:
    PhysicalExaminationInitialValues,
};
