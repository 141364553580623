import css from "@styled-system/css";
import styled from "styled-components";

export const Wrapper = styled.div`
  border: 1px solid;
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${css({
    backgroundColor: "background.primary",
    borderColor: "border.primary",
    color: "foreground.primary",
    borderRadius: [3],
  })}
`;

export const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: background-color 500ms, border 500ms;

  ${({ isOpen }) =>
    css({
      backgroundColor: isOpen ? "background.tertiary" : "background.primary",
      borderBottomColor: isOpen ? "border.primary" : "foreground.secondary",
      padding: [2],
    })}
`;

export const Content = styled.div`
  overflow: auto;
  transition: max-height 500ms, opacity 500ms, padding 500ms;

  ${({ isOpen }) =>
    css({
      maxHeight: isOpen ? "500px" : "0px",
      opacity: isOpen ? "1" : "0",
      paddingX: [2],
      paddingY: isOpen ? [1] : [0],
    })}
`;
