import React from "react";
import { Text } from "./components/text";
import { goatExaminationFormSteps, parentFormSteps } from "./constants";
import * as Styles from "./components/goats-list/goats-list.styles";

const isAcceptable = (item, acceptableValues) =>
  Array.isArray(acceptableValues)
    ? acceptableValues.some((option) => item.startsWith(option))
    : item.startsWith(acceptableValues);

const hasSelectedCheckboxValue = (value, acceptableValues) => {
  if (Array.isArray(value)) {
    return (
      value &&
      value.length &&
      value.find((item) => isAcceptable(item, acceptableValues))
    );
  }
  return isAcceptable(value, acceptableValues);
};

const w1CriteriaApply = ({
  current_season,
  agriculture_dominance,
  feeding_system,
}) =>
  current_season === "wet_season" &&
  (agriculture_dominance === "maize" ||
    agriculture_dominance === "millet" ||
    agriculture_dominance === "vegetables") &&
  (feeding_system === "free_roaming" ||
    feeding_system === "bush_herding" ||
    feeding_system === "tethered");

const w2CriteriaApply = ({
  current_season,
  agriculture_dominance,
  feeding_system,
}) =>
  current_season === "dry_season" &&
  agriculture_dominance === "vegetables" &&
  (feeding_system === "free_roaming" ||
    feeding_system === "bush_herding" ||
    feeding_system === "tethered");

const hasLowBCS = (bcs) => bcs === "bcs_1" || bcs === "bcs_2";

const isThinOrCachectic = (distance_condition) =>
  distance_condition === "thin" || distance_condition === "cachectic";

const n1CriteriaApply = (goat) => {
  const { goat_age, slaughter_weight, bcs } =
    goat[goatExaminationFormSteps.GENERAL_PARAMETERS];

  const { distance_condition, distance_skin } =
    goat[goatExaminationFormSteps.DISTANCE_EXAMINATION];

  return (
    hasSelectedCheckboxValue(goat_age, "weaner") &&
    slaughter_weight < 20 &&
    hasLowBCS(bcs) &&
    isThinOrCachectic(distance_condition) &&
    hasSelectedCheckboxValue(distance_skin, "coat_condition")
  );
};

const n2CriteriaApply = (goat) => {
  const { goat_age, goat_sex, doe_mating_age, slaughter_weight, bcs } =
    goat[goatExaminationFormSteps.GENERAL_PARAMETERS];

  const { distance_condition, distance_skin } =
    goat[goatExaminationFormSteps.DISTANCE_EXAMINATION];

  return (
    hasSelectedCheckboxValue(goat_age, "infant") &&
    goat_sex === "female" &&
    doe_mating_age > 19 &&
    slaughter_weight < 20 &&
    hasLowBCS(bcs) &&
    isThinOrCachectic(distance_condition) &&
    hasSelectedCheckboxValue(distance_skin, "coat_condition")
  );
};

const n3CriteriaApply = (goat) => {
  const { goat_age, goat_sex, slaughter_weight, bcs } =
    goat[goatExaminationFormSteps.GENERAL_PARAMETERS];

  const { distance_condition, distance_skin } =
    goat[goatExaminationFormSteps.DISTANCE_EXAMINATION];

  return (
    hasSelectedCheckboxValue(goat_age, "infant") &&
    goat_sex === "male" &&
    slaughter_weight < 20 &&
    hasLowBCS(bcs) &&
    isThinOrCachectic(distance_condition) &&
    hasSelectedCheckboxValue(distance_skin, "coat_condition")
  );
};

const n4CriteriaApply = (goat) => {
  const {
    goat_age,
    goat_sex,
    doe_mating_age,
    first_kid_age,
    doe_number_kids,
    doe_kids_survival,
    slaughter_weight,
    bcs,
  } = goat[goatExaminationFormSteps.GENERAL_PARAMETERS];

  const { distance_condition, distance_skin } =
    goat[goatExaminationFormSteps.DISTANCE_EXAMINATION];

  return (
    hasSelectedCheckboxValue(goat_age, "adult") &&
    goat_sex === "female" &&
    doe_mating_age > 19 &&
    first_kid_age > 24 &&
    doe_number_kids < 3 &&
    doe_kids_survival === "no" &&
    slaughter_weight < 20 &&
    hasLowBCS(bcs) &&
    isThinOrCachectic(distance_condition) &&
    hasSelectedCheckboxValue(distance_skin, "coat_condition")
  );
};

const n5CriteriaApply = (goat) => {
  const { goat_age, goat_sex, buck_breeding_age, slaughter_weight, bcs } =
    goat[goatExaminationFormSteps.GENERAL_PARAMETERS];

  const { distance_condition, distance_skin } =
    goat[goatExaminationFormSteps.DISTANCE_EXAMINATION];

  return (
    hasSelectedCheckboxValue(goat_age, "adult") &&
    goat_sex === "male" &&
    buck_breeding_age > 24 &&
    slaughter_weight < 20 &&
    hasLowBCS(bcs) &&
    isThinOrCachectic(distance_condition) &&
    hasSelectedCheckboxValue(distance_skin, "coat_condition")
  );
};

const hasHighFamacha = (values) =>
  values.famacha === "3" || values.famacha === "4" || values.famacha === "5";

const getGoatsThatNeedDeworming = (values) =>
  values[parentFormSteps.GOAT_FORM].goats.filter(
    (goat) =>
      hasLowBCS(goat[goatExaminationFormSteps.GENERAL_PARAMETERS].bcs) ||
      (hasHighFamacha(goat[goatExaminationFormSteps.PHYSICAL_EXAMINATION]) &&
        (w1CriteriaApply(values[parentFormSteps.FARMING_SYSTEMS]) ||
          w2CriteriaApply(values[parentFormSteps.FARMING_SYSTEMS])))
  );
const getAbscessGoats = (values) =>
  values[parentFormSteps.GOAT_FORM].goats.filter((goat) =>
    hasSelectedCheckboxValue(
      goat[goatExaminationFormSteps.PHYSICAL_EXAMINATION]
        .exam_head_abnormalities,
      "abscess"
    )
  );

const getAnimalId = (goat) =>
  goat[goatExaminationFormSteps.GENERAL_PARAMETERS].animal_id;

const getGrammaticalNumber = (count, singularNoun, pluralNoun) => {
  if (!count) return singularNoun;

  if (count > 1) return pluralNoun;

  return singularNoun;
};

const getGoatsWithRespiratoryIssues = (values) =>
  values[parentFormSteps.GOAT_FORM].goats.filter(
    (goat) =>
      hasSelectedCheckboxValue(
        goat[goatExaminationFormSteps.DISTANCE_EXAMINATION]
          .distance_respiration,
        ["laboured", "coughing", "harsh"]
      ) &&
      hasSelectedCheckboxValue(
        goat[goatExaminationFormSteps.PHYSICAL_EXAMINATION]
          .exam_head_abnormalities,
        "nasal_discharge"
      )
  );

const getGoatsWithNutritionAdvice = (values) => {
  return values[parentFormSteps.GOAT_FORM].goats.filter(
    (goat) =>
      n1CriteriaApply(goat) ||
      n2CriteriaApply(goat) ||
      n3CriteriaApply(goat) ||
      n4CriteriaApply(goat) ||
      n5CriteriaApply(goat)
  );
};

export const getAdviceItems = (values) => {
  const items = [];

  const goatsThatNeedDeworming = getGoatsThatNeedDeworming(values);

  if (goatsThatNeedDeworming && goatsThatNeedDeworming.length) {
    items.push({
      id: "worm_individual",
      title: "Worming advice",
      content: (
        <>
          <Text>
            Please de-worm{" "}
            {getGrammaticalNumber(
              goatsThatNeedDeworming.length,
              "individual",
              "individuals"
            )}{" "}
            <strong>
              {" "}
              {goatsThatNeedDeworming
                .map((goat) => getAnimalId(goat))
                .join(", ")}
            </strong>{" "}
            and record details of the wormer. Ensure that the{" "}
            {getGrammaticalNumber(
              goatsThatNeedDeworming.length,
              "animal is",
              "animals are"
            )}{" "}
            dosed correctly (5mg/kg):
          </Text>
          {goatsThatNeedDeworming.map((goat, i) => (
            <Styles.ListItem key={i}>
              <strong>
                {goat[goatExaminationFormSteps.GENERAL_PARAMETERS].animal_id}
              </strong>{" "}
              -{" "}
              {goat[goatExaminationFormSteps.PHYSICAL_EXAMINATION].goat_weight *
                5}
              mg
            </Styles.ListItem>
          ))}
          <Text>
            Please follow up with the farmer regarding the production parameters
            of the goats after 1 month.
          </Text>
        </>
      ),
    });
  }

  const goatsWithAbscess = getAbscessGoats(values);

  if (goatsWithAbscess && goatsWithAbscess.length) {
    items.push({
      id: "treat_abscess",
      title: "Abscess treatment",
      content: (
        <Text>
          Examine the comfort and hygiene of the housing and address any
          inadequacies.
        </Text>
      ),
    });
  }

  const goatsWithRespiratoryIssues = getGoatsWithRespiratoryIssues(values);

  if (goatsWithRespiratoryIssues && goatsWithRespiratoryIssues.length) {
    items.push({
      id: "respiratory_disease",
      title: "Respiratory disease",
      content: (
        <>
          <Text>Seek advice from your AVO concerning prompt treatment.</Text>
          <Text>
            Consider the potential confounding effect of other specific disease
            challenges.
          </Text>
          <Text>
            Examine the hygiene, comfort and ventilation of the khola in which{" "}
            {getGrammaticalNumber(
              goatsWithRespiratoryIssues.length,
              "animal",
              "animals"
            )}{" "}
            <strong>
              {goatsWithRespiratoryIssues
                .map((goat) => getAnimalId(goat))
                .join(", ")}
            </strong>{" "}
            {getGrammaticalNumber(
              goatsWithRespiratoryIssues.length,
              "is",
              "are"
            )}{" "}
            kept, and address any inadequacies.
          </Text>
          <Text>
            Hygiene: The khola should be clean and dry to avoid the accumulation
            of ammonia. Comfort: The khola should be dry and free from rough
            surfaces to enhance the animals wellbeing and resilience to disease.
            Ventilation: The khola should have a good air flow, but be free from
            drafts and protected from the elements.
          </Text>
        </>
      ),
    });
  }

  const goatsWithNutritionAdvice = getGoatsWithNutritionAdvice(values);

  if (goatsWithNutritionAdvice && goatsWithNutritionAdvice.length) {
    items.push({
      id: "nutrition_advice",
      title: "Nutrition advice",
      content: (
        <>
          <Text>
            The following{" "}
            {getGrammaticalNumber(
              goatsWithNutritionAdvice.length,
              "goat",
              "goats"
            )}{" "}
            <strong>
              {goatsWithNutritionAdvice
                .map((goat) => getAnimalId(goat))
                .join(", ")}
            </strong>{" "}
            {getGrammaticalNumber(
              goatsWithNutritionAdvice.length,
              "requires",
              "require"
            )}{" "}
            immediate supplementary feeding with a balanced protein, energy and
            fibre diet. Note that many available byproducts may be deficient in
            one or more of these components. Consult with your AVO.
          </Text>
          <Text>
            If supplementary feeding is not possible or affordable, animals
            should be sold for slaughter or traded to where better nutrition can
            be provided. This is necessary to avoid the risk of a total loss if
            the animal dies.
          </Text>
        </>
      ),
    });
  }

  return items;
};
