import React from "react";
import { Accordion } from "../../accordion";
import { BoxContent } from "../../box";
import { H2, Text } from "../../text";
import { Spacer } from "../../spacer";
import { ThreeButtons } from "../buttons/three-buttons";

export const GuidanceStep = ({
  items,
  onBackClick,
  onDoneClick,
  onRestartClick,
}) => {
  return (
    <>
      <BoxContent>
        <H2>Guidance</H2>
        <Spacer height={2} />
        {items
          .filter(
            ({ content }) =>
              content && (!Array.isArray(content) || content.length)
          )
          .map(({ id, title, content }) => {
            return (
              <>
                <Accordion
                  content={
                    <>
                      {Array.isArray(content)
                        ? content.map((line, i) => <Text key={i}>{line}</Text>)
                        : content}
                    </>
                  }
                  key={id}
                  title={title}
                />
                <Spacer height={3} />
              </>
            );
          })}
      </BoxContent>
      <Spacer height={2} />
      <ThreeButtons
        onLeftButtonClick={onRestartClick}
        leftButtonText="Restart the form"
        onMiddleButtonClick={onBackClick}
        middleButtonText="Back"
        onRightButtonClick={onDoneClick}
        rightButtonText="Done"
      />
    </>
  );
};
