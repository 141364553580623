import React from "react";
import { Button } from "../../../components/button";
import {
  ButtonContainer,
  ButtonsColumn,
} from "../../../components/button-container";
import { Spacer } from "../../../components/spacer";
import { HelperErrorMessage } from "../../form-elements/error";

export const ThreeButtons = ({
  onLeftButtonClick,
  leftButtonText,
  onMiddleButtonClick,
  middleButtonText,
  onRightButtonClick,
  rightButtonText,
  error,
}) => {
  return (
    <ButtonContainer>
      {error && <HelperErrorMessage>{error}</HelperErrorMessage>}
      <Spacer height={2} width={2} />
      <ButtonsColumn>
        <Button onClick={onLeftButtonClick} variant="tertiary">
          {leftButtonText}
        </Button>
        <Spacer width={2} />
        <Button onClick={onMiddleButtonClick} variant="secondary">
          {middleButtonText}
        </Button>
        <Spacer width={2} />
        <Button onClick={onRightButtonClick}>{rightButtonText}</Button>
      </ButtonsColumn>
    </ButtonContainer>
  );
};
