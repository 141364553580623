import React from "react";
import * as Styles from "./goats-list.styles";
import { Button } from "../button";
import { EmptyBox } from "../box";
import { Text } from "../text";
import { goatExaminationFormSteps } from "../../constants";

export const GoatsList = ({ goats, onAddGoatClick }) => {
  if (!goats || !goats.length) {
    return (
      <EmptyBox>
        <Text>Please add a goat</Text>
        <Button onClick={onAddGoatClick}>Add goat</Button>
      </EmptyBox>
    );
  }

  return goats.map((goat, i) => (
    <Styles.ListItem key={i}>
      Goat {i + 1} -{" "}
      <strong>
        {goat[goatExaminationFormSteps.GENERAL_PARAMETERS].animal_id}
      </strong>
    </Styles.ListItem>
  ));
};
