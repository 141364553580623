import React, { useState } from "react";
import { questionsByStep } from "../../../constants";
import { getComponentForQuestion, getValidationSchema } from "../../../utils";
import { BoxContent } from "../../box";
import { Spacer } from "../../spacer";
import { H2 } from "../../text";
import { TwoButtons } from "../buttons/two-buttons";

export const InitialStep = ({
  step,
  initialValues,
  onCancelClick,
  onNextClick,
}) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [helperError, setHelperError] = useState("");

  const onRightButtonClick = async () => {
    const schema = getValidationSchema(questionsByStep[step]);

    try {
      await schema.validate(values, { abortEarly: false });
      setErrors({});
      setHelperError("");
      onNextClick(values);
    } catch (error) {
      setErrors(
        error.errors.reduce(
          (accumulator, currentError) => ({ ...accumulator, ...currentError }),
          {}
        )
      );
      setHelperError(
        "Please check your answers and ensure you have completed all required form fields."
      );
    }
  };

  return (
    <>
      <BoxContent>
        <H2>{step}</H2>
        <Spacer height={2} />
        {questionsByStep[step].map((question) =>
          getComponentForQuestion(question, values, setValues, errors)
        )}
      </BoxContent>
      <Spacer height={2} />
      <TwoButtons
        error={helperError}
        onLeftButtonClick={onCancelClick}
        leftButtonText="Cancel"
        onRightButtonClick={onRightButtonClick}
        rightButtonText="Next"
      />
    </>
  );
};
